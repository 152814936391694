import React from 'react'
import {
    Typography
} from '@material-ui/core/index'

export default ({text}) =>  {
    
    return (
        <div>
            {text.split('\n').map(pg => (
                <div>
                    <Typography key={0} variant='body1'>
                        {pg} 
                    </Typography>
                    <br/>
                </div>))}
        </div>
    )
}