import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  main : {
    marginBottom: theme.spacing(10)
  },
  infoChip: {
    margin: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  },
  gridContainer: {
    padding: theme.spacing(2)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  }
}))

export default useStyles