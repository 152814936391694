import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/analytics'

const firebaseConfig = {
    apiKey: "AIzaSyDTPHfj5ZChkw3tvMi0Q3K4Dl_xmm9DV2s",
    authDomain: "imjoerackham.firebaseapp.com",
    databaseURL: "https://imjoerackham.firebaseio.com",
    projectId: "imjoerackham",
    storageBucket: "imjoerackham.appspot.com",
    messagingSenderId: "600558253228",
    appId: "1:600558253228:web:cd51d4718e3ada72f79b09",
    measurementId: "G-ZBVXNFYVS5"
}

export function initApp() {
    if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig)
        logEvent("init_app")
    }
}

export function addToCollection(collection, object) {
    initApp()
    firebase.firestore().collection(collection).add(object)
}

export function logEvent(event) {
    initApp()
    firebase.analytics().logEvent(event)
}