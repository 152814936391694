import React from 'react';

import {
    Code as CodeIcon,
    BubbleChart as BubbleIcon,
    EmojiFoodBeverage as TeaIcon,
    CropOriginal as PictureIcon,
    School as SchoolIcon,
    Forward as ForwardIcon,
    LiveHelp as QuestionIcon,
    LockOpen as LockOpenIcon,
    Extension as JigsawIcon,
    EmojiObjects as LightbulbIcon,
    Create as WriteIcon,
    HelpOutline as HelpIcon
} from '@material-ui/icons'

const mapping = {
    "BMB": <BubbleIcon />,
    "T": <TeaIcon />,
    "PP": <PictureIcon />,
    "TADA": <SchoolIcon />,
    "FF": <ForwardIcon />,
    "VER": <QuestionIcon />,
    "UNI": <SchoolIcon />,
    "A": <SchoolIcon />,
    "PD": <LockOpenIcon />,
    "REVENGE": <JigsawIcon />,
    "ADA": <LightbulbIcon/>,
    "BLOG": <WriteIcon />,
    "ADVICE": <HelpIcon />
}

export default ({ slug, iconProps }) => {

    return mapping[slug] || <CodeIcon {...iconProps} />
}