import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import './App.css'

let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#6ec6ff',
      dark: '#0069c0',
      main: '#2196f3',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#ffc947',
      dark: '#c66900',
      main: '#ff9800',
      contrastText: '#ffffff'
    }
  },

  typography: {
    fontFamily: ['Varela', 'Times New Roman']
  }

})

theme = responsiveFontSizes(theme)

export default theme
