import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  infoChip: {
    margin: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(25),
    height: theme.spacing(25)
  },
  gridContainer: {
    padding: theme.spacing(2)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  heading: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1)
  },
  root: {
    display: 'flex',
  },
  cover: {
    width: 151,
    height: 151
  },
  content: {
    flex: '1 0 auto',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    textDecoration: 'none',
  }
}))

export default useStyles