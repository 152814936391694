import React from 'react'
import {
    Typography,
    Card,
    CardContent,
    CardActionArea,
    CardMedia,
    Link
} from '@material-ui/core/index'
import useStyles from './style'

export default ({ title, image, published, articleLink }) => {

    const classes = useStyles()

    return (<Card className={classes.root}>
        <a href={articleLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: ''}}>
            <Link>
                <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image={image}
                    title="Article Image"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                    {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    Published {published}
                    </Typography>
                </CardContent>
                </CardActionArea>
            </Link>
        </a>
    </Card>)
}
