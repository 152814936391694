import React from 'react'
import {
  Typography,
  Grid,
  Table,
  TableBody,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  Divider
} from '@material-ui/core/index'
import {
  Star as FilledStarIcon,
  StarBorder as EmptyStarIcon
} from '@material-ui/icons/index'
import { langs, tools, maths } from '../../Objects/skills'
import useStyles from './style'
import { useMediaQuery} from 'react-responsive'

export default () => {

  const classes = useStyles()

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })

  return (
    <div>
      <Grid container align='center' className={classes.gridContainer}>
        <Grid item xs={12}>
          <Typography variant='h3'>
            What can I do?
                  </Typography>
        </Grid>
      </Grid>

      <Divider />

      {/* Programming */}
      <Grid container align='left' className={classes.gridContainer}>
        <Grid item xs={12} className={classes.heading}>
          <Typography variant='h4'>
            Programming...
                  </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>
            University has been a great oppertunity to practice my Programming. As well as being taught a few languages in dedicated lectures I've had to get quite good at picking up new languages on my own as their required for projects. Here's s list of programming (and markup) languages i've worked with ranked by how much experience i've had.
          </Typography>
        </Grid>
      </Grid>

      <Grid container justify='center' className={classes.gridContainer}>
        <Grid item xs={isDesktopOrLaptop ? 6 : 12}>
          <TableContainer component={Paper} style={{ backgroundColor: '#6ec6ff', maxHeight: 300 }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Language</TableCell>
                  <TableCell align="right">Confidence</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {langs.sort((a, b) => b.val - a.val).map((l, i) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {l.name}
                    </TableCell>
                    <TableCell align="right">
                      {Array(3).fill(1).map((_, i) => i < l.val ? <FilledStarIcon /> : <EmptyStarIcon />)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Divider />

      {/* Tools */}
      <Grid container align='left' className={classes.gridContainer}>
        <Grid item xs={12} className={classes.heading}>
          <Typography variant='h4'>
            Tools...
                  </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>
            As well as programming languages, I've also had to learn how to use tools and techologies that work with my code. Earlier projects focussed on creating a single executable but towards the end of my degree projects became more complex and how they were deployed, documented and supported became just as important as the quality of the code. Here are some tools i'm confident working with:
                  </Typography>
        </Grid>
      </Grid>

      <Grid container justify='center' className={classes.gridContainer}>
        {tools.map((t, i) => <Grid item key={i}>
          <Paper style={{ padding: 20, margin: 20, backgroundColor: '#6ec6ff' }}>
            {t}
          </Paper>
        </Grid>)}
      </Grid>

      <Divider />

      {/* Maths */}
      <Grid container align='left' className={classes.gridContainer}>
        <Grid item xs={12} className={classes.heading}>
          <Typography variant='h4'>
            Maths...
                  </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>
            I've also had the chance to develop some of my maths skills. Here are some areas I've studied at University:
                  </Typography>
        </Grid>
      </Grid>

      <Grid container justify='center' className={classes.gridContainer}>
        {maths.map((m, i) => <Grid item key={i}>
          <Paper style={{ padding: 20, margin: 20, backgroundColor: '#6ec6ff' }}>
            {m}
          </Paper>
        </Grid>)}
      </Grid>

    </div>
  )
}