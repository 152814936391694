import axios from 'axios'

export function get_most_recent_track() {
    return axios.get('https://ws.audioscrobbler.com/2.0',
        {
            'params': {
                'method': 'user.getrecenttracks',
                'user': 'TheKingTwig',
                'api_key': '643a5bb5895f244a6471a74e568e7b92',
                'format': 'json'
            }
        })
        .then(resp => resp.data.recenttracks.track[0])
        .then(track => {
            return {
                name: track.name,
                artist: track.artist['#text'],
                imgURL: track.image[2]['#text'],
                songURL: track.url
            }
        })
}

function get_artist_image(artist) {
    return axios.get('https://ws.audioscrobbler.com/2.0',
        {
            'params': {
                'method': 'artist.gettopalbums',
                'artist': artist,
                'api_key': '643a5bb5895f244a6471a74e568e7b92',
                'format': 'json',
            }
        })
        .then(resp => resp.data.topalbums.album[0])
        .then(album => {
            return album.image[2]['#text']
        })
}

export async function get_top_artist() {
    var { name, playcount, artistURL } = await axios.get('https://ws.audioscrobbler.com/2.0',
        {
            'params': {
                'method': 'user.gettopartists',
                'user': 'TheKingTwig',
                'api_key': '643a5bb5895f244a6471a74e568e7b92',
                'format': 'json',
                'period': '1month',
            }
        })
        .then(resp => resp.data.topartists.artist[0])
        .then(artist => {
            return {
                name: artist.name,
                playcount: artist.playcount,
                artistURL: artist.url
            }
        })

    return get_artist_image(name).then(url => {
        return {
            imgURL: url,
            name: name,
            playcount: playcount,
            artistURL: artistURL
        }
    })
}