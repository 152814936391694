import React, { useEffect, useRef } from 'react'
import {
    Typography,
    Grid,
    GridList,
    GridListTile,
    Chip,
    Button,
    Paper
} from '@material-ui/core/index'
import {
    GetApp as DownloadIcon,
    Link as LinkIcon,
    YouTube as YouTubeIcon,
    GitHub as GitHubIcon,
  } from '@material-ui/icons'
import useStyles from './style'
import TextParser from '../TextParser'
import { useMediaQuery } from 'react-responsive'

export default ({info, index, setRef}) => {

    const classes = useStyles()
    const ref = useRef(null)
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })

    useEffect(() => {
        setRef(ref, index)
    }, [index, setRef])

    return (
        <Paper ref={ref} className={classes.main} elevation={3}>
            {/* Title */}
            <Grid container  justify='center' className={classes.gridContainer}>
                <Grid item xs={12}>
                    <Typography variant='h3'>
                        {info.title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h5'>
                        {info.strapline}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h5'>
                        {info.time}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justify='center' spacing={1} className={classes.gridContainer}>
                {info.skills.map(skill => <Chip
                    className={classes.infoChip}
                    label={skill}
                    variant='outlined'
                    color='secondary'
                />)}
            </Grid>

            {/* Description */}
            <Grid container align='left' className={classes.gridContainer}>
                <Grid item xs={12}>
                    <TextParser text={info.description}/>
                </Grid>
            </Grid>

            {/* Links */}
            <Grid container justify='center' spacing={2} className={classes.gridContainer}>
                {info.links.map(link => <Grid item> <Button 
                    variant='contained' 
                    color='secondary' 
                    href={link.type === 'DL' ? './Projects/' + info.slug + '/' + link.link : link.link}>
                    {link.label} 
                    {link.type === "DL" && <DownloadIcon className={classes.rightIcon}/>}
                    {link.type === "LK" && <LinkIcon className={classes.rightIcon}/>}
                    {link.type === "VID" && <YouTubeIcon className={classes.rightIcon}/>}
                    {link.type === "GIT" && <GitHubIcon className={classes.rightIcon}/>}
                </Button> </Grid>)}
            </Grid>

            {/* Images */}
            <Grid container align='center' justify='center' className={classes.gridContainer}>
                <GridList cellHeight={300} spacing={20} className={classes.gridList} cols={isDesktopOrLaptop ? 2 : 1}>
                    {Array(info.images).fill(1).map((_, i) => {
                        return (<GridListTile key={i}>
                            <img src={'./Projects/' + info.slug + '/' + i + '.png'} alt={'hello'} />
                        </GridListTile>)}
                    )}
                </GridList>
            </Grid>


        </ Paper>
    )
}