import React from 'react'
import {
    Typography,
    Grid,
    Avatar,
    Link,
} from '@material-ui/core/index'
import avatar from '../../Objects/question.png'
import useStyles from './style'

export default ({ pages }) => {

    const classes = useStyles()

    return (
        <div>
            <Grid container justify='center' className={classes.gridContainer} spacing='2'>
                <Grid item>
                    <Avatar
                        className={classes.avatar}
                        src={avatar}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h2'>
                        Looks like your lost...
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h5'>
                        I can't find the page you asked for.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Link href='/' > Go to my homepage </Link>
                </Grid>
            </Grid>
        </div>
    )
}