import React, { useRef } from 'react';
import Project from './Project/Project'
import ModuleTable from './ModuleTable/ModuleTable'
import eduInfo from '../Objects/eduInfo'
import {
  Grid
} from '@material-ui/core/index'
import { useMediaQuery } from 'react-responsive'

function App() {

  const initRef = useRef(null)
  const setRef = () => {}
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })

  return (
    <div ref={initRef}>
        <Project info={eduInfo.uni} index={0} setRef={setRef}/>
        <Grid container justify='center' spacing={3}>
        {eduInfo.modules.map((m, i) => (
          <Grid item xs={isDesktopOrLaptop ? 4 : 6}> 
            <ModuleTable year={i + 1} modules={m}/>
          </Grid>
        ))}
        </Grid>
        <Project info={eduInfo.alevel} index={1} setRef={setRef}/>
    </div>
  );
}

export default App
