import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    maxWidth: 450
  },
  media: {
    height: 140
  }
}))

export default useStyles