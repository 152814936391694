import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  cover: {
    width: 151,
    height: 153.57
  },
  content: {
    flex: '1 0 auto',
    minWidth: 150,
    maxWidth: 350,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default useStyles