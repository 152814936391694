import React, { useState } from 'react'
import {
    Button,
    TextField,
    Paper,
    Grid,
    Snackbar
} from '@material-ui/core/index'
import "firebase/firestore"
import * as FIREBASE from '../../APIs/Firebase'
import useStyles from './style'

export default () => {

    const classes = useStyles()

    const [contactName, setContactName] = useState("")
    const [contactMsg, setContactMsg] = useState("")
    const [contactTopic, setContactTopic] = useState("")
    const [contactError, setContactError] = useState(false)

    const [alertOpen, setAlertOpen] = useState(false)

    const handleFormChange = (setter, value) => {
        setContactError(false)
        setter(value)
    }

    const handleClose = () => {
        setAlertOpen(false) 
    }    

    const submitContactMe = () => {
        if (contactName.length === 0 || contactMsg.length === 0 || contactName.length === 0) {
            setContactError(true)
        } else {
            var form = {
                name: contactName,
                topic: contactTopic,
                message: contactMsg
            }
            FIREBASE.addToCollection("guestbook", form)

            setContactName("")
            setContactMsg("")
            setContactTopic("")

            setAlertOpen(true)
        }
    }

    return (<Paper>
        <form className={classes.root} noValidate autoComplete="off">
            <Grid container align='center'>
                <Grid item xs={12}>
                    <TextField 
                        error={contactError}
                        value={contactName} 
                        onChange={(e) => handleFormChange(setContactName, e.target.value)}
                        label="Your Name"  
                    />
                    <TextField 
                        error={contactError}
                        value={contactTopic} 
                        onChange={(e) => handleFormChange(setContactTopic, e.target.value)}
                        label="Message Topic" 
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField 
                        error={contactError}
                        label="Your Message"
                        value={contactMsg}
                        onChange={(e) => handleFormChange(setContactMsg, e.target.value)}
                        multiline 
                        fullWidth={true}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant='contained'
                        color='secondary'
                        className={classes.button}
                        onClick={submitContactMe}
                    >
                        Submit!
                    </Button>
                </Grid>
            </Grid>  
        </form>

        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={alertOpen}
            onClose={handleClose}
            message="Message submitted successfully"
        />
       
    </Paper>)
}