import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    heading: {
        padding: theme.spacing(2)
    },
    link: {
        color: 'black',
        padding: theme.spacing(2),
        textDecoration: 'none'
    }
}))

export default useStyles