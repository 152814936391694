import React from 'react'
import {
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableBody,
    TableRow
} from '@material-ui/core/index'
import useStyles from './style'

export default ({year, modules}) => {

    const classes = useStyles()

    return (
        <TableContainer component={Paper} className={classes.table} >
        <Table stickyHeader aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Year {year} Modules </TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {modules.map((m, i) => (
                <TableRow key={i}>
                <TableCell component="th" scope="row">
                    {m}
                </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    )
}