import React from 'react';
import {
    AppBar,
    Avatar,
    Divider,
    Toolbar,
    Typography,
    useScrollTrigger,
} from '@material-ui/core/index'
import useStyles from './style'
import { Link } from 'react-router-dom'
import {
    FiberManualRecord as DotIcon
} from '@material-ui/icons/index'
import avatar from '../../Objects/avatars/burger.jpg'

function ElevationScroll({ children }) {
    return React.cloneElement(children, {
        elevation: useScrollTrigger({ disableHysteresis: true, threshold: 0 })
            ? 4
            : 0
    })
}

export default ({ pages, page, extLinks }) => {
    const classes = useStyles()

    return (
        <div>
            <ElevationScroll>
                <AppBar>
                    <Toolbar>
                        <Avatar
                            src={avatar}
                        />

                        <Typography variant='h6' className={classes.heading}>
                            Joe Rackham
                        </Typography>
                        <Divider orientation="vertical" />

                        {pages.map((p, i) => <Link
                            key={i}
                            className={(i === page) ? classes.selectedLink : classes.unselectedLink}
                            to={p.link}
                        >
                            {p.label}
                        </Link>)}

                        <DotIcon style={{ fontSize: 'small' }} />

                        {extLinks.map((e, i) => <a
                            key={i}
                            className={classes.unselectedLink}
                            href={e.link}
                            target='_blank'
                            rel="noopener noreferrer"
                        >
                            {e.label}
                        </a>)}

                    </Toolbar>
                </AppBar>
            </ElevationScroll>

        </div>
    )
}