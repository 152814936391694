import React from 'react'
import {
    Typography,
    Card,
    CardContent,
    CardMedia
} from '@material-ui/core/index'
import useStyles from './style'

export default ({ title, subtitle, imgURL, musicURL }) => {

    const classes = useStyles()

    return (<Card className={classes.root}>
        <div className={classes.details}>
            <CardContent className={classes.content}>
                <Typography
                    noWrap 
                    component="h5" 
                    variant="h5">
                    {title}
                </Typography>
                <Typography
                    nowrap 
                    variant="subtitle1" 
                    color="textSecondary">
                    {subtitle}
                </Typography>
                <img
                    src='./sound.gif'
                    alt='Album cover' />
            </CardContent>
        </div>
        <a href={musicURL} target="_blank" rel="noopener noreferrer">
            <CardMedia
                className={classes.cover}
                image={imgURL}
                title="Album cover"
            />
        </a>
    </Card>)
}