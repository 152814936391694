import React, { useState, useEffect } from 'react'
import {
    Typography,
    Grid,
    GridList,
    GridListTile,
    Chip,
    Avatar,
    Button
} from '@material-ui/core/index'
import {
    GitHub as GitHubIcon,
    Room as LocationIcon,
    Email as EmailIcon,
    Build as BuildIcon,
    ChildCare as FunIcon,
    Work as WorkIcon
} from '@material-ui/icons/index'
import masthead from '../../Objects/masthead'
import avatar from '../../Objects/avatars/burger.jpg'
import { 
    intro as introTxt, 
    projects as projTxt, 
    free as freeTxt, 
    blog as blogTxt,
    contact as contactTxt 
} from '../../Objects/homeTxt'
import useStyles from './style'
import TextParser from '../TextParser'
import ArticleCard from '../ArticleCard/ArticleCard'
import AlbumCard from '../AlbumCard/AlbumCard'
import { Redirect } from 'react-router-dom'
import * as LASTFM from '../../APIs/LastFM'
import * as MEDIUM from '../../APIs/Medium'
import TextLoop from 'react-text-loop'
import { useMediaQuery } from 'react-responsive'
import ContactForm from '../ContactForm/ContactForm'

const images = ['./Projects/BMB/0.png', './Projects/ADA/0.png', './Projects/T/0.png', './Projects/FF/0.png']

export default () => {

    const classes = useStyles()

    const [seeProj, setSeeProj] = useState(false)
    const [seeFun, setSeeFun] = useState(false)

    const [most_recent_track, setMRT] = useState({})
    const [top_artist, setTA] = useState({})

    const [mediumArticles, setMediumArticles] = useState([])
    const [randomArticleNum, setRandomArticleNum] = useState(0)

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })

    useEffect(() => {
        LASTFM.get_most_recent_track().then(data => setMRT(data))
        LASTFM.get_top_artist().then(data => setTA(data))

        MEDIUM.get_articles().then(articles => {
            setMediumArticles(articles)
            var randNum = Math.floor(Math.random() * (articles.length - 1)) + 1
            setRandomArticleNum(randNum)
        })        
    }, [setMediumArticles, setMRT, setTA])

    return (
        <div>
            {/* Masthead */}
            <Grid container justify='center' className={classes.gridContainer}>
                <Grid item>
                    <Avatar
                        className={classes.avatar}
                        src={avatar}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h2'>
                        {masthead.name}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h5'>
                        <TextLoop springConfig={{ stiffness: 70, damping: 31 }} adjustingSpeed={500}>
                            {masthead.straplines.map(line =>
                                <span>{line}</span>    
                            )}
                        </TextLoop>{" "}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justify='center' spacing={1} className={classes.gridContainer}>
                <a
                    href={'mailto:' + masthead.email}
                    className={classes.link}>
                    <Chip
                        className={classes.infoChip}
                        label={masthead.email}
                        icon={<EmailIcon />}
                        color='secondary'
                        clickable />
                </a>
                <Chip
                    className={classes.infoChip}
                    label={masthead.location}
                    icon={<LocationIcon />}
                    color='secondary'
                />
                <a
                    href={'https://github.com/' + masthead.git}
                    className={classes.link}>
                    <Chip
                        className={classes.infoChip}
                        label={masthead.git}
                        icon={<GitHubIcon />}
                        color='secondary'
                        clickable
                    />
                </a>
                <Chip
                    className={classes.infoChip}
                    label={masthead.work}
                    icon={<WorkIcon />}
                    color='secondary'
                />
            </Grid>

            {/* Intro */}
            <Grid container align='left' className={classes.gridContainer}>
                <Grid item xs={12} className={classes.heading}>
                    <Typography variant='h4'>
                        Hi I'm Joe...
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextParser text={introTxt} />
                </Grid>
            </Grid>

            {/* Projects */}
            <Grid container align='left' className={classes.gridContainer}>
                <Grid item xs={12} className={classes.heading}>
                    <Typography variant='h4'>
                        What I'm working on...
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.heading}>
                    <Typography key={0} variant='body1'>
                        {projTxt}
                    </Typography>
                </Grid>
            </Grid>

            <GridList 
                cellHeight={150} 
                spacing={20} 
                className={classes.heading} 
                cols={isDesktopOrLaptop ? 4 : 1}
            >
                {images.map((img, i) => {
                    return (<GridListTile key={i}>
                        <img src={img} alt={'hello'} />
                    </GridListTile>)
                })}
            </GridList>

            <Grid container justify='center'>
                <Grid item className={classes.heading}>
                    <Button
                        variant='contained'
                        color='secondary'
                        className={classes.button}
                        onClick={() => setSeeProj(true)}
                    >
                        See my projects!
                        <BuildIcon className={classes.rightIcon} />
                    </Button>
                    <Button
                        variant='contained'
                        color='secondary'
                        className={classes.button}
                        onClick={() => setSeeFun(true)}
                    >
                        See some fun stuff!
                        <FunIcon className={classes.rightIcon} />
                    </Button>
                </Grid>
            </Grid>

            <Grid container align='left' className={classes.gridContainer}>
                <Grid item xs={12} className={classes.heading}>
                    <Typography key={0} variant='body1'>
                        {blogTxt}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container align='left' justify='center' spacing={5}>
                <Grid item>
                    <Typography variant='button'>
                        My most recent blog article:
                    </Typography>

                    <ArticleCard {...mediumArticles[0]} /> 
                </Grid>
                <Grid item>
                    <Typography variant='button'>
                        Another Randomly Chosen Article:
                    </Typography>

                    <ArticleCard {...mediumArticles[randomArticleNum]} /> 
                </Grid>
            </Grid>

            {/* Free Time */}
            <Grid container align='left' className={classes.gridContainer}>
                <Grid item xs={12} className={classes.heading}>
                    <Typography variant='h4'>
                        And in my free time...
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextParser text={freeTxt} />
                </Grid>
            </Grid>

            <Grid container align='left' justify='center' spacing={5}>
                <Grid item>
                    <Typography variant='button'>
                        The last track I listened to:
                    </Typography>
                    <AlbumCard
                        title={most_recent_track.name}
                        subtitle={most_recent_track.artist}
                        imgURL={most_recent_track.imgURL}
                        musicURL={most_recent_track.songURL} />
                </Grid>
                <Grid item>
                    <Typography variant='button'>
                        My top artist last month:
                    </Typography>
                    <AlbumCard
                        title={top_artist.name}
                        subtitle={top_artist.playcount + ' plays'}
                        imgURL={top_artist.imgURL}
                        musicURL={top_artist.artistURL} />
                </Grid>
            </Grid>

            {/* Contact Me */}
            <Grid container align='left' className={classes.gridContainer}>
                <Grid item xs={12} className={classes.heading}>
                    <Typography variant='h4'>
                        Want to say hi?
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextParser text={contactTxt} />
                </Grid>
            </Grid>

            <Grid container align='left' justify='center' spacing={5}>
                <Grid item>
                    <Typography variant='button'>
                       Leave me a message:
                    </Typography>
                    <ContactForm/>
                </Grid>
            </Grid>

            {seeProj && <Redirect to='/projects' />}
            {seeFun && <Redirect to='/funthings' />}
        </div>
    )
}