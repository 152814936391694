import axios from 'axios'

const mediumURL = 'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@joerackham';

export function get_articles() {
    return axios.get(mediumURL)
        .then(resp => resp.data.items)
        .then(articles => articles.map(article => {
            var pubParts = article.pubDate.split(" ")
            
            return {
                title: article.title,
                image: article.thumbnail,
                published: pubParts[0],
                articleLink: article.link
            }
        }))
}