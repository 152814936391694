import React, { useState, useRef } from 'react'
import Project from './Project/Project'
import Sidebar from './Sidebar/Sidebar'
import ProjectToIcon from '../Objects/ProjectToIcon'
import { useMediaQuery} from 'react-responsive'

export default ({title, projects}) => {

  const initRef = useRef(null)

  const initSide = () => {
    return projects.map(f => { 
      return { label: f.title, icon: <ProjectToIcon slug={f.slug} />, ref: {initRef} }})
  }

  const [side, setSide] = useState(initSide())

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })

  const setRef = ((ref, i) => {
    const cpy = side
    cpy[i].ref = ref
    setSide(cpy)
  })

  return (
    <div ref={initRef} style={{paddingLeft: isDesktopOrLaptop ? 260 : 0}}>
        {isDesktopOrLaptop && <Sidebar title={title} items={side} />}
        {projects.map((f, i) => <Project key={i} info={f} index={i} setRef={setRef}/>)}
    </div>
  );
}