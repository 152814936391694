import React from 'react';
import {
    AppBar,
    Avatar,
    Divider,
    Toolbar,
    Typography,
    IconButton,
    useScrollTrigger,
} from '@material-ui/core/index'
import useStyles from './style'
import {
    Menu as MenuIcon,
} from '@material-ui/icons/index'
import avatar from '../../Objects/avatars/burger.jpg'

function ElevationScroll({ children }) {
    return React.cloneElement(children, {
        elevation: useScrollTrigger({ disableHysteresis: true, threshold: 0 })
            ? 4
            : 0
    })
}

export default ({ openMenu }) => {
    const classes = useStyles()

    return (
        <div>
            <ElevationScroll>
                <AppBar>
                    <Toolbar>
                        <IconButton 
                            edge="start" 
                            className={classes.menuButton} 
                            color="inherit" aria-label="menu"
                            onClick={openMenu}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Avatar
                            src={avatar}
                        />

                        <Typography variant='h6' className={classes.heading}>
                            Joe Rackham
                        </Typography>
                        <Divider orientation="vertical" />

                    </Toolbar>
                </AppBar>
            </ElevationScroll>

        </div>
    )
}