import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  table: {
    backgroundColor: '#6ec6ff', 
    maxHeight: 400,
    marginBottom: theme.spacing(10)
  }
}))

export default useStyles