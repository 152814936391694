import React, { useState, useEffect } from 'react';
import './App.css';
import Topbar from './Components/Topbar/Topbar'
import Home from './Components/Home/Home'
import Projects from './Components/Projects'
import FunThings from './Components/FunThings'
import Education from './Components/Education'
import Skills from './Components/Skills/Skills'
import PageNotFound from './Components/PageNotFound/PageNotFound'
import useStyles from './style';
import { Container } from '@material-ui/core';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import theme from './theme'
import ParticleBackground from 'react-particle-backgrounds'
import MobileTopbar from './Components/MobileTopbar/MobileTopbar'
import { useMediaQuery} from 'react-responsive'
import MobileNavDrawer from './Components/MobileNavDrawer/MobileNavDrawer'
import * as FIREBASE from './APIs/Firebase'


const pages = [
  { label: 'Home', link: '/', component: Home },
  { label: 'Projects', link: '/projects', component: Projects },
  { label: 'Fun Stuff', link: '/funthings', component: FunThings },
  { label: 'Education', link: '/education', component: Education },
  { label: 'Skills', link: '/skills', component: Skills }
]

const extLinks = [
  { label: 'My CV', link: './cv.pdf' },
  { label: 'My LinkedIn', link: 'https://www.linkedin.com/in/joe-rackham-775231151/' },
  { label: "My Medium Blog", link: "https://joerackham.medium.com/" }
]

function App() {

  const classes = useStyles();

  const [page, setPage] = useState(0)
  const [menuOpen, setMenuOpen] = useState(false)
  const openMenu = () => setMenuOpen(true)
  const closeMenu = () => setMenuOpen(false)

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })

  const particleSettings = {
    canvas: {
      canvasFillSpace: true,
      width: 200,
      height: 200,
      useBouncyWalls: false
    },
    particle: {
      particleCount: 50,
      color: '#808080',
      minSize: 2,
      maxSize: 5
    },
    velocity: {
      directionAngle: 0,
      directionAngleVariance: 360,
      minSpeed: 0.5,
      maxSpeed: 1
    },
    opacity: {
      minOpacity: 0,
      maxOpacity: 0.5,
      opacityTransitionTime: 3000
    }
  }

  useEffect(() => {
    FIREBASE.initApp()
  })

  return (
    <div className="App">
      <div className={classes.particles}>
        <ParticleBackground settings={particleSettings} />
      </div>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          { isDesktopOrLaptop 
            ?  <Topbar pages={pages} page={page} extLinks={extLinks} />
            : (<div>
                <MobileTopbar openMenu={openMenu}/>
                <MobileNavDrawer open={menuOpen} closeMenu={closeMenu} pages={pages} extLinks={extLinks}/>
              </div>)
          }
          
          <div 
            className={classes.main}
            onClick={closeMenu}
          >
            <Container className={classes.container} >
              <Switch>
                {pages.map((p, i) => <Route
                  key={i}
                  exact
                  path={p.link}
                  render={() => {
                    let Element = p.component
                    FIREBASE.logEvent('visit_page' + p.link)
                    setPage(i)
                    window.scrollTo(0, 0)
                    return <Element pages={pages} />
                  }}
                />)}

                <Route render={() => <PageNotFound pages={pages} />} />

              </Switch>
            </Container>
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App
