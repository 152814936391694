import { makeStyles } from '@material-ui/core'
import { white } from 'material-ui/styles/colors'

const useStyles = makeStyles(theme => ({
  heading: {
    padding: theme.spacing(2)
  },
  selectedLink: {
    color: white,
    padding: theme.spacing(2),
    textDecoration: 'underline'
  },
  unselectedLink: {
    color: white,
    padding: theme.spacing(2),
    textDecoration: 'none'
  }
}))

export default useStyles
