import React, { useState, useEffect } from 'react'
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Container
} from '@material-ui/core'
import useStyles from './style'

const getDimensions = ele => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

export default ({title, items}) => {
  
  const classes = useStyles()

  const [visibleSection, setVisibleSection] = useState(items[0] ? items[0].label : undefined)

  useEffect(() => {
    const handleScroll = () => {
      //const { height: headerHeight } = getDimensions(headerRef.current);
      const scrollPosition = window.scrollY + 150;

      const selected = items.find(({ref}) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }

        return false;
      });

      if (selected && selected.label !== visibleSection) {
        setVisibleSection(selected.label);
      } else if (!selected && visibleSection) {
        // setVisibleSection();
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSection, items]);

  const drawer = (
    <div>
      <div className={classes.drawerSideBlock}>
        <Container className={classes.container}>
          <Typography variant='h4'>
            {title}
          </Typography>
        </Container>
      </div>
      <Divider variant='middle' />
      <List>
        {items.map((p, i) => (
          <ListItem 
            button 
            key={i}
            selected={visibleSection === p.label} 
            onClick={() => p.ref.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            }) }
          >
            <ListItemIcon> {p.icon} </ListItemIcon>
            <ListItemText primary={p.label} />
          </ListItem> 
        ))}
      </List>
    </div>
  )

  return (
    <nav className={classes.drawer}>
      <Drawer
        variant='permanent'
        open
        ModalProps={{ keepMounted: true }}
        classes={{ paper: classes.drawerPaper }}
      >
        {drawer}
      </Drawer>
    </nav>
  )
}
