import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  drawer: {
    display: 'flex'
  },
  drawerPaper: {
    width: 240,
    marginTop: theme.spacing(8)
  },
  drawerSideBlock: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    ...theme.mixins.toolbar
  },
  drawerCenteredBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    ...theme.mixins.toolbar
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  avatar: {
    margin: 10,
    width: 60,
    height: 60
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  selected: {
    backgroundColor: '#f17f71'
  } 
}))

export default useStyles
