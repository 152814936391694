import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1)
    },
    textArea: {
        width: 500
    },
    root: {
        width:500,
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    }
}))

export default useStyles