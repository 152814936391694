import React from 'react'
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
    Divider,
    Typography
} from '@material-ui/core/index'
import { Link } from 'react-router-dom'
import useStyles from './style'

export default ({open, closeMenu, pages, extLinks}) => {
    const classes = useStyles()

    return <Drawer anchor='left' open={open} onClose={closeMenu}>
      <Typography variant='h5' className={classes.heading}>
        Joe Rackham
      </Typography>
      <Divider/>
      <List onClick={closeMenu}>
        {pages.map((page) => (
          <ListItem component={Link} to={page.link} className={classes.link}>
            <ListItemText primary={page.label} />
          </ListItem>
        ))}
        <Divider/>
        {extLinks.map((link) => (
          <ListItem component='a' href={link.link} target='_blank' rel="noopener noreferrer" className={classes.link}>
            <ListItemText primary={link.label} />
          </ListItem>
        ))}
      </List>
    </Drawer>
}